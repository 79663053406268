import { Dispatch, SetStateAction, useEffect } from 'react';

import { useAlert } from '@/hooks/useAlert';
import { useConfirm } from '@/hooks/useConfirm';
import useInvalidateFromMutation from '@/queries/useInvalidateFromMutation';
import { QueryKeyFunctionType } from '@/types/Queries.types';

export default function useFakerOff<T>(
	queryKeyFunction: QueryKeyFunctionType,
	selectData: T[],
	setSelectData: Dispatch<SetStateAction<T[]>>,
	setClearRows: Dispatch<SetStateAction<boolean>>,
) {
	const { alertMessage } = useAlert();
	const { confirmMessage } = useConfirm();

	const tcsNoList = selectData.map((data: any) => {
		if (data && typeof data === 'object' && 'tcs_no' in data) return data['tcs_no'];
	});

	const fakerOff = useInvalidateFromMutation(queryKeyFunction, 'fakerOff', tcsNoList);

	const fakerOffMutate = async () => {
		console.log(tcsNoList);
		if (tcsNoList.length < 1) {
			alertMessage(`FAKER를 미가동 시키실 비상벨을 선택해주세요.`);
			return false;
		}

		if (await confirmMessage(`선택하신 비상벨의 FAKER를 미가동 시키시겠습니까?`)) {
			fakerOff.mutate();
		} else {
			setSelectData([]);
			setClearRows((prev) => !prev);
		}
	};

	useEffect(() => {
		if (fakerOff.isSuccess) {
			setSelectData([]);
			setClearRows((prev) => !prev);
		}
	}, [fakerOff.isSuccess, setSelectData, setClearRows]);

	return () => {
		return (
			<button type="button" className="btn btn-green" onClick={fakerOffMutate}>
				FAKER 미가동
			</button>
		);
	};
}
