export const commonQueryKey = (() => {
	return {
		login: () => ['login'],
	};
})();

// 회원 쿼리키

export const memberQueryKey = (() => {
	const endpoint = 'member';

	return {
		chkDup: () => [endpoint, 'chkIdDup'], // ID 중복 확인
		insert: () => [endpoint, 'insert'], // 회원 등록
		list: () => [endpoint, 'list'], // 회원 목록
		detail: () => [endpoint, 'detail'], // 회원 상세
		update: () => [endpoint, 'update'], // 회원 수정
		delete: () => [endpoint, 'delete'], // 회원 삭제
	};
})();

// 비상벨 쿼리키
export const deviceQueryKey = (() => {
	const endpoint = 'device';

	return {
		chkDup: () => [endpoint, 'chkMacDup'], // MAC 중복 확인
		insert: () => [endpoint, 'insert'], // 비상벨 신규 등록
		list: () => [endpoint, 'list'], // 비상벨 목록
		excelDown: () => [endpoint, 'excelDown'], // 엑셀 다운로드
		detail: () => [endpoint, 'detail'], // 비상벨 상세
		update: () => [endpoint, 'update'], // 비상벨 정보 수정
		delete: () => [endpoint, 'delete'], // 비상벨 삭제

		fakerOn: () => [endpoint, 'fakerOn'], //FAKER 가동
		fakerOff: () => [endpoint, 'fakerOff'], //FAKER 미가동
	};
})();

// 통계 쿼리키
export const statisticsQueryKey = (() => {
	const endpoint = 'statistics';

	const alert = 'alert';
	const alive = 'alive';

	return {
		alertList: () => [endpoint, alert, 'list'], // 알림 목록
		alertExcelDown: () => [endpoint, alert, 'excelDown'], // 알림 엑셀 다운로드
		alertDetail: () => [endpoint, alert, 'detail'], // 알림 상세

		aliveList: () => [endpoint, alive, 'list'], // 고장 목록
		aliveExcelDown: () => [endpoint, alive, 'excelDown'], // 고장 엑셀 다운로드
		aliveDetail: () => [endpoint, alive, 'detail'], // 고장 상세
		aliveUpdate: () => [endpoint, alive, 'update'], // 고장 수정
	};
})();

// 호선 / 역사명 쿼리키
(() => {
	const endpoint = 'ls';

	return {
		chkLineDup: () => [endpoint, 'chkLineDup'], // 호선 중복 확인
		lineInsert: () => [endpoint, 'lineInsert'], // 호선 등록
		lineList: () => [endpoint, 'lineList'], // 호선 목록
		lineDetail: () => [endpoint, 'lineDetail'], // 호선 상세
		lineUpdate: () => [endpoint, 'lineUpdate'], // 호선 수정
		lineDelete: () => [endpoint, 'lineDelete'], // 호선 삭제

		chkSttDup: () => [endpoint, 'chkSttDup'], // 역사명 중복 확인
		sttInsert: () => [endpoint, 'sttInsert'], // 역사명 등록
		sttList: () => [endpoint, 'sttList'], // 역사명 목록
		sttDetail: () => [endpoint, 'sttDetail'], // 역사명 상세
		sttUpdate: () => [endpoint, 'sttUpdate'], // 역사명 수정
		sttDelete: () => [endpoint, 'sttDelete'], // 역사명 삭제
	};
})();
