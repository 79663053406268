import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { deviceTypeDic, flagDic, lineDic } from '@/constants/dictionary';
import CustomInput from '@/features/ui/form/CustomInput';
import CustomSelect from '@/features/ui/form/CustomSelect';
import CustomRow from '@/features/ui/layout/CustomRow';
import { useContentsModal } from '@/hooks/useContentsModal';
import useRequiredValueCheck from '@/hooks/useRequiredValueCheck';
import { SecureStorage } from '@/plugin/crypto';
import { deviceQueryKey } from '@/queries/_querykey';
import { postMutation } from '@/queries/_utils';
import useDuplicateCheck from '@/queries/useDuplicateCheck';
import useInvalidateFromMutation from '@/queries/useInvalidateFromMutation';
import { deviceIdState } from '@/state/device';
import { DeviceInfoDataType } from '@/types/Device.types';
import { formatOnlyMacAddress, formatOnlyPhoneNumber } from '@/utils/stringUtils';

export default function DeviceInfo() {
	const secureStorage = new SecureStorage(sessionStorage);
	const userInfo = secureStorage.getItem('user-storage', 'user-storage');
	const { member_flag: memberFlag } = userInfo;

	const isMasAdm = userInfo.member_flag === 1;
	const isNorAdm = userInfo.member_flag === 6;

	const initialDeviceInfo = {
		tcsDeviceType: '',
		tcsUnique: '',
		tcsName: '',
		tcsLine: '',
		tcsStation: '',
		tcsSimpAddr: '',
		tcsCompAddr: '',
		tcsSttCompAddr: '',
		tcsMac: '',
		tcsMatchPhone: '',
		tcsSerial: '',
		tcsMemo: '',
		tcsFlag: '',
		hmId: userInfo.member_id,
	};

	const id = useRecoilValue(deviceIdState);

	const [deviceInfo, setDeviceInfo] = useState<DeviceInfoDataType>(initialDeviceInfo);
	const [savedDeviceInfo, setSavedDeviceInfo] = useState<DeviceInfoDataType>(initialDeviceInfo);

	// 상세 정보
	const updateMutation = useMutation({
		mutationFn: () => postMutation([...deviceQueryKey.detail(), null, { tcsNo: id }]),
		onSuccess: (data) => {
			const deviceInfo = {
				...initialDeviceInfo,
				tcsDeviceType: data.tcs_deviceType,
				tcsUnique: data.tcs_unique,
				tcsName: data.tcs_name,
				tcsSimpAddr: data.tcs_simpAddr,
				tcsLine: data.tcs_line,
				tcsStation: data.tcs_station,
				tcsCompAddr: data.tcs_compAddr,
				tcsSttCompAddr: data.tcs_stt_compAddr,
				tcsMac: data.tcs_mac,
				tcsMatchPhone: data.tcs_matchPhone,
				tcsSerial: data.tcs_serial,
				tcsMemo: data.tcs_memo,
				tcsFlag: data.tcs_flag,
			};

			setDeviceInfo(deviceInfo);
			setSavedDeviceInfo(deviceInfo);
		},
	});

	// 중복 체크
	const inputMacRef = useRef<HTMLInputElement>(null);
	const isSameTcsMac = !!deviceInfo['tcsMac'].trim() && savedDeviceInfo['tcsMac'] === deviceInfo['tcsMac'];
	const { isDuplicateChecked, handleDuplicateCheck } = useDuplicateCheck(
		deviceQueryKey.chkDup(),
		{ tcsMac: deviceInfo.tcsMac },
		'MAC',
		'deviceMac',
		isSameTcsMac,
	);

	// 필수 체크
	const requiredDeviceInfo: Record<string, string | string[]> = {
		tcsMac: 'MAC',
	};
	const requiredValueCheck = useRequiredValueCheck(deviceInfo, requiredDeviceInfo);

	// 등록 / 수정
	const insertQueryKey = id ? 'update' : 'insert';
	const insertQueryParams = id ? { ...deviceInfo, tcsNo: id } : deviceInfo;
	const insertMutation = useInvalidateFromMutation(deviceQueryKey, insertQueryKey, insertQueryParams);

	const handleChangeDeviceInfo = (name: string, value: string) => {
		setDeviceInfo((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();

		if (requiredValueCheck(isDuplicateChecked)) {
			insertMutation.mutate();
		}
	};

	const { closeContentModal } = useContentsModal();

	const handleModalClose = () => {
		setDeviceInfo(initialDeviceInfo);
		closeContentModal();
	};

	useEffect(() => {
		if (id) updateMutation.mutate();
		else setDeviceInfo(initialDeviceInfo);
	}, [id]);

	useEffect(() => {
		if (insertMutation.isSuccess) {
			handleModalClose();
		}
	}, [insertMutation.isSuccess]);

	return (
		<>
			<div className="modal-header">
				<h4 className="modal-title">
					{isMasAdm ? `비상벨 ${id ? '정보 수정' : '신규 등록'}` : ''}
					{!isMasAdm ? `비상벨 ${id ? '정보' : '신규 등록'}` : ''}
				</h4>
			</div>
			<form
				className="form-info form-info-vertical px-2"
				onSubmit={(event) => handleSubmit(event)}
				style={{ maxWidth: '900px', width: '90vw' }}
			>
				<div className="modal-body">
					<CustomRow>
						<div className="form-grid">
							<CustomSelect
								required={true}
								labelTitle="설치모델"
								name="tcsDeviceType"
								defaultValue={deviceInfo.tcsDeviceType}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
								enableBlankSelect={true}
								optionDictionary={deviceTypeDic}
							/>
						</div>
						<div className="form-grid">
							<CustomInput
								required={true}
								labelTitle="식별번호"
								name="tcsUnique"
								defaultValue={`${deviceInfo.tcsUnique}`}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
							/>
						</div>
					</CustomRow>
					<CustomRow>
						<div className="form-grid">
							<CustomSelect
								required={true}
								labelTitle="호선"
								name="tcsLine"
								defaultValue={deviceInfo.tcsLine}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
								optionDictionary={lineDic}
								enableBlankSelect={true}
							/>
						</div>
						<div className="form-grid">
							<CustomInput
								required={true}
								labelTitle="역사명"
								name="tcsStation"
								defaultValue={`${deviceInfo.tcsStation}`}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
							/>
						</div>
					</CustomRow>
					<CustomRow>
						<div className="form-grid">
							<CustomInput
								required={true}
								labelTitle="세부설치위치"
								name="tcsCompAddr"
								defaultValue={`${deviceInfo.tcsCompAddr}`}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
							/>
						</div>
						{memberFlag === 1 && (
							<div className="form-grid">
								<CustomInput
									_ref={inputMacRef}
									required={true}
									labelTitle="MAC"
									name="tcsMac"
									defaultValue={deviceInfo.tcsMac}
									isOnlyText={memberFlag !== 1}
									handleState={handleChangeDeviceInfo}
									handlePattern={formatOnlyMacAddress}
									siblings={
										<button
											type="button"
											className="btn btn-default"
											onClick={() => {
												if (inputMacRef.current?.checkValidity()) handleDuplicateCheck(deviceInfo.tcsMac);
												else inputMacRef.current?.reportValidity();
											}}
											disabled={isSameTcsMac}
										>
											중복 확인
										</button>
									}
									pattern=".{17,17}"
									title="총 12자리를 입력해주세요."
									readOnly={!isSameTcsMac && isDuplicateChecked}
								/>
							</div>
						)}
					</CustomRow>
					<CustomRow>
						<div className="form-grid">
							<CustomInput
								required={true}
								labelTitle="전화번호"
								name="tcsMatchPhone"
								defaultValue={deviceInfo.tcsMatchPhone}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
								handlePattern={formatOnlyPhoneNumber}
								pattern=".{11,}"
								title="9자 이상을 입력해주세요."
							/>
						</div>
						<div className="form-grid">
							<CustomInput
								required={true}
								labelTitle="라우터"
								name="tcsSerial"
								defaultValue={deviceInfo.tcsSerial}
								isOnlyText={memberFlag !== 1}
								handleState={handleChangeDeviceInfo}
								handlePattern={formatOnlyPhoneNumber}
								pattern=".{11,}"
								title="9자 이상을 입력해주세요."
							/>
						</div>
					</CustomRow>
					{memberFlag === 1 && (
						<CustomRow>
							<div className="form-grid">
								<CustomSelect
									required={true}
									labelTitle="가동상태"
									name="tcsFlag"
									defaultValue={deviceInfo.tcsFlag}
									isOnlyText={memberFlag !== 1}
									handleState={handleChangeDeviceInfo}
									optionDictionary={flagDic}
									enableBlankSelect={true}
								/>
							</div>
							<div className="form-grid"></div>
						</CustomRow>
					)}
					{(isMasAdm || isNorAdm) && (
						<CustomRow>
							<div className="form-grid">
								<CustomInput
									labelTitle="메모"
									name="tcsMemo"
									defaultValue={deviceInfo.tcsMemo}
									isOnlyText={memberFlag !== 1}
									handleState={handleChangeDeviceInfo}
									pattern=".{0,100}"
									title="100자 이하로 입력해주세요."
								/>
							</div>
						</CustomRow>
					)}
				</div>
				<div className="modal-footer">
					{memberFlag === 1 && (
						<button type="submit" className="btn btn-navy">
							{id ? '수정' : '등록'}
						</button>
					)}
					<button type="button" className="btn btn-default" onClick={handleModalClose}>
						{memberFlag === 1 ? '취소' : '닫기'}
					</button>
				</div>
			</form>
		</>
	);
}
