import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import DeviceInfo from '@/features/device/DeviceInfo';
import DeviceList from '@/features/device/DeviceList';
import ButtonExcel from '@/features/ui/button/ButtonExcel';
import ButtonRefetch from '@/features/ui/button/ButtonRefetch';
import LoadingFrame from '@/features/ui/common/LoadingFrame';
import PageBody from '@/features/ui/layout/PageBody';
import PageHeader from '@/features/ui/layout/PageHeader';
import { useContentsModal } from '@/hooks/useContentsModal';
import { SecureStorage } from '@/plugin/crypto';
import { deviceQueryKey } from '@/queries/_querykey';
import { postQuery } from '@/queries/_utils';
import useDeleteData from '@/queries/useDeleteData';
import useFakerOff from '@/queries/useFakerOff';
import useFakerOn from '@/queries/useFakerOn';
import { deviceIdState } from '@/state/device';
import { DeviceListType } from '@/types/Device.types';
import { getValueOrEmptyFromObject } from '@/utils/objectUtils';

export default function Device() {
	const secureStorage = new SecureStorage(sessionStorage);
	const userInfo = secureStorage.getItem('user-storage', 'user-storage');
	const { member_flag: memberFlag } = userInfo;

	const isMasterAdmin = memberFlag === 1;

	const initialParams = {
		searchDTO: {
			memberFlag: getValueOrEmptyFromObject(userInfo, 'member_flag'),
			memberViewlist: getValueOrEmptyFromObject(userInfo, 'member_viewlist'),
			searchType: '',
			searchWord: '',
		},
		orderDTO: {
			orderType: '',
			orderWord: '',
		},
		pageDTO: {
			curPage: 1,
			pagePerRow: secureStorage.getItem('user-storage', 'user-storage').member_dataPerPage,
		},
	};

	const pageTitle = '비상벨 관리';

	const [_, setId] = useRecoilState(deviceIdState);
	const { openContentModal } = useContentsModal();

	const [params, setParams] = useState(initialParams);
	const { data, isLoading } = useQuery({
		queryKey: [...deviceQueryKey.list(), params],
		queryFn: postQuery,
	});
	const [selectData, setSelectData] = useState<DeviceListType[]>([]);
	const [clearRows, setClearRows] = useState(false);

	const getFakerOnButton = useFakerOn(deviceQueryKey, selectData, setSelectData, setClearRows);
	const getFakerOffButton = useFakerOff(deviceQueryKey, selectData, setSelectData, setClearRows);
	const getDeleteButton = useDeleteData(userInfo.member_id, 'tcs', deviceQueryKey, selectData, setSelectData);

	const handleRegisterationClick = () => {
		setId(null);
		openContentModal(<DeviceInfo />);
	};

	const handleDeviceClick = (device: DeviceListType) => {
		setId(device.tcs_no);
		openContentModal(<DeviceInfo />);
	};

	const listProps = {
		initialParams,
		params,
		data: data?.deviceList,
		pageMap: data?.pageMap,
		setSelectData,
		setParams,
		handleDeviceClick,
	};

	const isMobile = /Mobi/i.test(window.navigator.userAgent);
	const isSearchWord = params.searchDTO.searchWord !== null && params.searchDTO.searchWord !== '';

	// 가로 모드   >   스크롤 이동
	useEffect(() => {
		const landscape = () => {
			if (!window.matchMedia('(orientation: portrait)').matches) {
				const page = document.querySelector('.page');
				if (page) {
					page.scrollTo({ top: 205 });
				}
			}
		};
		window.addEventListener('load', landscape);
		window.addEventListener('resize', landscape);
	}, []);

	return (
		<div className="page">
			<PageHeader title={pageTitle}>
				{!isMobile ? (
					isSearchWord ? (
						<ButtonExcel
							queryKey={deviceQueryKey.excelDown()}
							params={params}
							filename={params.searchDTO.searchWord + ' 비상벨 현황'}
						/>
					) : (
						<ButtonExcel queryKey={deviceQueryKey.excelDown()} params={params} filename="비상벨 현황" />
					)
				) : null}
				<ButtonRefetch />
			</PageHeader>
			<PageBody title="비상벨 목록">
				{isMasterAdmin && (
					<div className="d-flex justify-content-end">
						{getFakerOnButton()}
						{getFakerOffButton()}
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<button type="button" className="btn btn-navy" onClick={handleRegisterationClick}>
							등록하기
						</button>
						{getDeleteButton()}
					</div>
				)}
				<DeviceList {...listProps} />
			</PageBody>
			{isLoading && <LoadingFrame />}
		</div>
	);
}
