export const titleDic = [new Option('회원', 'member'), new Option('비상벨', 'tcs')];

export const memberSearchTypeDic = [
	new Option('아이디', 'member_id'),
	new Option('호선 / 역사명', 'member_viewlist'),
	new Option('이름', 'member_name'),
	new Option('전화번호', 'member_phone'),
	new Option('이메일', 'member_email'),
];

export const deviceTypeDic = [
	new Option('ECB-ST-10K', 'ECB-ST-10K'),
	new Option('ECB-ST-20T', 'ECB-ST-20T'),
	new Option('ECB-ST-20BK', 'ECB-ST-20BK'),
	new Option('ECB-ST-20VK', 'ECB-ST-20VK'),
	new Option('ECB-ST-30T', 'ECB-ST-30T'),
];
export const lineDic = [
	new Option('1호선', '1호선'),
	new Option('2호선', '2호선'),
	new Option('3호선', '3호선'),
	new Option('4호선', '4호선'),
	new Option('5호선', '5호선'),
	new Option('6호선', '6호선'),
	new Option('7호선', '7호선'),
	new Option('8호선', '8호선'),
	new Option('예비', '예비'),
];
export const compAddressDic = [
	new Option('여자화장실', '여자화장실'),
	new Option('고객안전실', '고객안전실'),
	new Option('수유실', '수유실'),
	new Option('아이센터', '아이센터'),
	//new Option('직접입력', '직접입력'),
];
export const flagDic = [
	new Option('정상', '0'),
	new Option('고장', '1'),
	new Option('미설치', '2'),
	new Option('전력차단', '3'),
	new Option('통신불량', '4'),
];
export const deviceSearchTypeDic = [
	new Option('설치역사', 'tcs_name'),
	new Option('전화번호', 'tcs_matchPhone'),
	new Option('라우터', 'tcs_serial'),
	new Option('MAC', 'tcs_mac'),
];
export const deviceSearchTypeDic2 = [
	new Option('설치역사', 'tcs_name'),
	new Option('전화번호', 'tcs_matchPhone'),
	new Option('라우터', 'tcs_serial'),
];

export const alertSearchTypeDic = [
	new Option('알림역사', 'alert_name'),
	new Option('알림유형', 'alert_type'),
	new Option('전화번호', 'alert_phone'),
	new Option('라우터', 'alert_serial'),
	new Option('MAC', 'tcs_mac'),
];
export const alertSearchTypeDic2 = [
	new Option('알림역사', 'alert_name'),
	new Option('알림유형', 'alert_type'),
	new Option('전화번호', 'alert_phone'),
	new Option('라우터', 'alert_serial'),
];
export const aliveSearchTypeDic = [
	new Option('고장역사', 'alive_name'),
	new Option('전화번호', 'alive_phone'),
	new Option('라우터', 'alive_serial'),
	new Option('MAC', 'tcs_mac'),
];
export const aliveSearchTypeDic2 = [
	new Option('고장역사', 'alive_name'),
	new Option('전화번호', 'alive_phone'),
	new Option('라우터', 'alive_serial'),
];
