import { Dispatch, SetStateAction, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';

import { deviceSearchTypeDic, deviceSearchTypeDic2 } from '@/constants/dictionary';
import ListDataTable from '@/features/ui/list/ListDataTable';
import Pagination from '@/features/ui/list/Pagination';
import SearchCondition from '@/features/ui/list/SearchCondition';
import useCall from '@/hooks/useCall';
import { SecureStorage } from '@/plugin/crypto';
import { PageMapType, SearchParamsType } from '@/types/Common.types';
import { DeviceListType } from '@/types/Device.types';

interface PropsType {
	initialParams: SearchParamsType;
	params: SearchParamsType;
	data: DeviceListType[];
	pageMap: PageMapType;
	setSelectData: Dispatch<SetStateAction<DeviceListType[]>>;
	setParams: Dispatch<SetStateAction<SearchParamsType>>;
	handleDeviceClick: (data: DeviceListType) => void;
}

export default function DeviceList({
	initialParams,
	params,
	data,
	pageMap,
	setSelectData,
	setParams,
	handleDeviceClick,
}: PropsType) {
	const secureStorage = new SecureStorage(sessionStorage);
	const userInfo = secureStorage.getItem('user-storage', 'user-storage');

	const tableData = useMemo(
		() =>
			data?.map((item, itemIndex) => {
				return { ...item, no: pageMap?.startRow + itemIndex };
			}),
		[data, pageMap?.startRow],
	);

	const copyToClipboard = (text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				alert('MAC 주소가 복사되었습니다!');
			})
			.catch((err) => {
				console.error('MAC 주소 복사에 실패하였습니다.', err);
			});
	};

	const { call, isMobile } = useCall();

	/** 목록 테이블의 열을 구성하기 위한 데이터 */
	const columns: TableColumn<DeviceListType>[] = [
		{ name: 'No.', selector: (row) => row.no },
		{ name: '호선', selector: (row) => row['tcs_line'], sortable: true, sortField: 'tcs_line' },
		{
			name: '역사명',
			selector: (row) => row['tcs_station'],
			cell: (d) => (
				<div onClick={() => handleDeviceClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.tcs_station}
				</div>
			),
			sortable: true,
			sortField: 'tcs_station',
		},
		{
			name: '식별번호',
			selector: (row) => row['tcs_unique'],
			cell: (d) => (
				<div onClick={() => handleDeviceClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.tcs_unique}
				</div>
			),
			sortable: true,
			sortField: 'tcs_unique',
		},
		{
			name: '세부설치위치',
			selector: (row) => row['tcs_compAddr'],
			cell: (d) => (
				<div onClick={() => handleDeviceClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.tcs_compAddr}
				</div>
			),
			sortable: true,
			sortField: 'tcs_compAddr',
		},
		{
			name: '가동상태',
			selector: (row) => row['tcs_flag'],
			cell: (d) => {
				const flag = d['tcs_flag'];
				return flag === 0 ? (
					<div onClick={() => handleDeviceClick(d)} style={{ color: '#00FF00' }}>
						정상
					</div>
				) : flag === 1 ? (
					<div onClick={() => handleDeviceClick(d)} style={{ color: '#FF0000' }}>
						고장
					</div>
				) : flag === 2 ? (
					<div onClick={() => handleDeviceClick(d)}>미설치</div>
				) : flag === 3 ? (
					<div onClick={() => handleDeviceClick(d)}>전력차단</div>
				) : (
					<div onClick={() => handleDeviceClick(d)}>통신불량</div>
				);
			},
			sortable: true,
			sortField: 'tcs_flag',
		},
		userInfo.member_flag === 1
			? {
					name: 'FAKER',
					selector: (row) => row['tcs_faker'],
					cell: (d) => {
						const faker = d['tcs_faker'];
						return faker === 0 ? (
							<div onClick={() => handleDeviceClick(d)} style={{ color: '#00FF00' }}>
								미가동
							</div>
						) : faker === 1 ? (
							<div onClick={() => handleDeviceClick(d)} style={{ color: '#FF0000' }}>
								가동
							</div>
						) : (
							<div></div>
						);
					},
					sortable: true,
					sortField: 'tcs_faker',
			  }
			: {},
		/*
		{
			name: 'MAC',
			cell: (row) => {
				const mac = row['tcs_mac'];
				return (
					<div onClick={() => copyToClipboard(mac)} style={{ cursor: 'pointer' }}>
						{mac}
					</div>
				);
			},
			sortable: true,
			sortField: 'tcs_mac',
		},
		*/
		{
			name: '전화번호',
			cell: (row) => {
				const phoneNumber = row['tcs_matchPhone'];
				return isMobile ? (
					<div onClick={() => call(phoneNumber)} style={{ cursor: 'pointer' }}>
						{phoneNumber}
					</div>
				) : (
					<>{phoneNumber}</>
				);
			},
			sortable: true,
			sortField: 'tcs_matchPhone',
		},
		{ name: '라우터', selector: (row) => row['tcs_serial'], sortable: true, sortField: 'tcs_serial' },

		userInfo.member_flag === 1 || userInfo.member_flag === 6
			? { name: '마지막신호', selector: (row) => row['tcs_ALdate'], sortable: true, sortField: 'tcs_ALdate' }
			: {},
		{
			name: '메모',
			selector: (row) => row['tcs_memo'],
			cell: (d) => (
				<div onClick={() => handleDeviceClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.tcs_memo}
				</div>
			),
			sortable: true,
			sortField: 'tcs_memo',
		},
	];

	const paginationProps = {
		pageMap,
		setParams,
	};

	let searchConditionProps;
	if (userInfo.member_flag === 1) {
		searchConditionProps = {
			initialParams,
			params,
			setParams,
			typeChildren: deviceSearchTypeDic,
		};
	} else {
		searchConditionProps = {
			initialParams,
			params,
			setParams,
			typeChildren: deviceSearchTypeDic2,
		};
	}

	return (
		<>
			<SearchCondition {...searchConditionProps} />
			<ListDataTable
				tableConfiguration={{ columns, tableData }}
				selectRowsState={{
					selectableRows: true,
					clearSelectedRows: true,
					selectedRows: tableData,
					setSelectedRows: setSelectData,
				}}
				onRowClicked={handleDeviceClick}
				pagination={{
					defaultPagination: false,
					customPagination: <Pagination {...paginationProps} />,
				}}
				onSortParams={{
					params,
					setParams,
				}}
			/>
		</>
	);
}
