import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import AlertInfo from '@/features/statistics/AlertInfo';
import AlertList from '@/features/statistics/AlertList';
import ButtonExcel from '@/features/ui/button/ButtonExcel';
import ButtonRefetch from '@/features/ui/button/ButtonRefetch';
import LoadingFrame from '@/features/ui/common/LoadingFrame';
import PageBody from '@/features/ui/layout/PageBody';
import PageHeader from '@/features/ui/layout/PageHeader';
import { useContentsModal } from '@/hooks/useContentsModal';
import { SecureStorage } from '@/plugin/crypto';
import { statisticsQueryKey } from '@/queries/_querykey';
import { postQuery } from '@/queries/_utils';
import { alertIdState } from '@/state/alert';
import { AlertListType } from '@/types/Statistics.types';
import { getValueOrEmptyFromObject } from '@/utils/objectUtils';

export default function Alert() {
	const secureStorage = new SecureStorage(sessionStorage);
	const userInfo = secureStorage.getItem('user-storage', 'user-storage');

	const initialParams = {
		searchDTO: {
			memberFlag: getValueOrEmptyFromObject(userInfo, 'member_flag'),
			memberViewlist: getValueOrEmptyFromObject(userInfo, 'member_viewlist'),
			searchType: '',
			searchWord: '',
		},
		orderDTO: {
			orderType: '',
			orderWord: '',
		},
		pageDTO: {
			curPage: 1,
			pagePerRow: secureStorage.getItem('user-storage', 'user-storage').member_dataPerPage,
		},
	};

	const pageTitle = '알림 관리';

	const [_, setId] = useRecoilState(alertIdState);
	const { openContentModal } = useContentsModal();

	const [params, setParams] = useState(initialParams);
	const { data, isLoading } = useQuery({
		queryKey: [...statisticsQueryKey.alertList(), params],
		queryFn: postQuery,
	});

	const handleAlertClick = (alert: AlertListType) => {
		setId(alert.alertNo);
		openContentModal(<AlertInfo />);
	};

	const listProps = {
		initialParams,
		params,
		data: data?.alertList,
		pageMap: data?.pageMap,
		setParams,
		handleAlertClick,
	};

	const isMobile = /Mobi/i.test(window.navigator.userAgent);
	const isSearchWord = params.searchDTO.searchWord !== null && params.searchDTO.searchWord !== '';

	// 가로 모드   >   스크롤 이동
	useEffect(() => {
		const landscape = () => {
			if (!window.matchMedia('(orientation: portrait)').matches) {
				const page = document.querySelector('.page');
				if (page) {
					page.scrollTo({ top: 205 });
				}
			}
		};
		window.addEventListener('load', landscape);
		window.addEventListener('resize', landscape);
	}, []);

	return (
		<div className="page">
			<PageHeader title={pageTitle}>
				{!isMobile ? (
					isSearchWord ? (
						<ButtonExcel
							queryKey={statisticsQueryKey.alertExcelDown()}
							params={params}
							filename={params.searchDTO.searchWord + ' 알림 현황'}
						/>
					) : (
						<ButtonExcel queryKey={statisticsQueryKey.alertExcelDown()} params={params} filename="알림 현황" />
					)
				) : null}
				<ButtonRefetch />
			</PageHeader>
			<PageBody title="알림 목록">
				<AlertList {...listProps} />
			</PageBody>
			{isLoading && <LoadingFrame />}
		</div>
	);
}
