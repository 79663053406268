import { Dispatch, SetStateAction, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';

import { aliveSearchTypeDic } from '@/constants/dictionary';
import ListDataTable from '@/features/ui/list/ListDataTable';
import Pagination from '@/features/ui/list/Pagination';
import SearchCondition from '@/features/ui/list/SearchCondition';
import useCall from '@/hooks/useCall';
import { SecureStorage } from '@/plugin/crypto';
import { PageMapType, SearchParamsType } from '@/types/Common.types';
import { AliveListType } from '@/types/Statistics.types';

interface PropsType {
	initialParams: SearchParamsType;
	params: SearchParamsType;
	data: AliveListType[];
	pageMap: PageMapType;
	setParams: Dispatch<SetStateAction<SearchParamsType>>;
	handleAliveClick: (data: AliveListType) => void;
}

export default function AliveList({ initialParams, params, data, pageMap, setParams, handleAliveClick }: PropsType) {
	const tableData = useMemo(
		() =>
			data?.map((item, itemIndex) => {
				console.log(item);
				return { ...item, no: pageMap?.startRow + itemIndex };
			}),
		[data],
	);

	const { call, isMobile } = useCall();

	/** 목록 테이블의 열을 구성하기 위한 데이터 */
	const columns: TableColumn<AliveListType>[] = [
		{ name: 'No.', selector: (row) => row.no },
		{ name: '호선', selector: (row) => row['aliveLine'], sortable: true, sortField: 'aliveLine' },
		{
			name: '역사명',
			selector: (row) => row['aliveStation'],
			cell: (d) => (
				<div onClick={() => handleAliveClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.aliveStation}
				</div>
			),
			sortable: true,
			sortField: 'aliveStation',
		},
		{
			name: '세부설치위치',
			selector: (row) => row['aliveSimpaddr'],
			cell: (d) => (
				<div onClick={() => handleAliveClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.aliveSimpaddr}
				</div>
			),
			sortable: true,
			sortField: 'aliveSimpaddr',
		},
		{ name: '라우터', selector: (row) => row['aliveSerial'], sortable: true, sortField: 'aliveSerial' },
		{
			name: '전화번호',
			cell: (row) => {
				const phoneNumber = row['alivePhone'];
				return isMobile ? (
					<div onClick={() => call(phoneNumber)} style={{ cursor: 'pointer' }}>
						{phoneNumber}
					</div>
				) : (
					<>{phoneNumber}</>
				);
			},
			sortable: true,
			sortField: 'alivePhone',
		},
		{ name: '고장일시', selector: (row) => row['aliveDate'], sortable: true, sortField: 'aliveDate' },
		{
			/*
		{ name: '수리일시', selector: (row) => row['aliveDate2'], sortable: true, sortField: 'aliveDate2' },
		{ name: '처리소요시간', selector: (row) => row['aliveTime'], sortable: true, sortField: 'aliveTime' },
			*/
		},
		{
			name: '메모',
			selector: (row) => row['aliveMemo'],
			cell: (d) => (
				<div onClick={() => handleAliveClick(d)} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					{d.aliveMemo}
				</div>
			),
			sortable: true,
			sortField: 'aliveMemo',
		},
	];

	const paginationProps = {
		pageMap,
		setParams,
	};

	const searchConditionProps = {
		initialParams,
		params,
		setParams,
		typeChildren: aliveSearchTypeDic,
	};

	return (
		<>
			<SearchCondition {...searchConditionProps} />
			<ListDataTable
				tableConfiguration={{ columns, tableData }}
				selectRowsState={{
					selectableRows: false,
				}}
				onRowClicked={handleAliveClick}
				pagination={{
					defaultPagination: false,
					customPagination: <Pagination {...paginationProps} />,
				}}
				onSortParams={{
					params,
					setParams,
				}}
			/>
		</>
	);
}
