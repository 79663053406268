export const menuConfig = [
	{
		memberFlag: '1',
		path: '/member',
		icon: 'fa-solid fa-user',
		title: '회원 관리',
	},
	{
		memberFlag: '1,4,5,6',
		path: '/device',
		icon: 'fa-solid fa-bell',
		title: '비상벨 관리',
	},
	{
		memberFlag: '1,4,5,6',
		path: '/statistics/alert',
		icon: 'fa-solid fa-envelope',
		title: '알림 관리',
	},
	{
		memberFlag: '1,6',
		path: '/statistics/alive',
		icon: 'fa-solid fa-cog',
		title: '고장 관리',
	},
];

export default menuConfig;
