import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRecoilValue } from 'recoil';

import CustomInput from '@/features/ui/form/CustomInput';
import CustomText from '@/features/ui/form/CustomText';
import CustomRow from '@/features/ui/layout/CustomRow';
import { useContentsModal } from '@/hooks/useContentsModal';
import { SecureStorage } from '@/plugin/crypto';
import { statisticsQueryKey } from '@/queries/_querykey';
import { postMutation } from '@/queries/_utils';
import useInvalidateFromMutation from '@/queries/useInvalidateFromMutation';
import { aliveIdState } from '@/state/alive';
import { AliveInfoDataType } from '@/types/Alive.types';

export default function AliveInfo() {
	const secureStorage = new SecureStorage(sessionStorage);
	const userInfo = secureStorage.getItem('user-storage', 'user-storage');
	const { member_flag: memberFlag } = userInfo;

	const initialAliveInfo = {
		aliveDevicetype: '',
		aliveType: '',
		aliveMac: '',
		aliveName: '',
		aliveLine: '',
		aliveStation: '',
		aliveSimpaddr: '',
		aliveSerial: '',
		alivePhone: '',
		aliveDate: '',
		aliveDate2: '',
		aliveTime: '',
		aliveMemo: '',
		hmId: userInfo.member_id,
	};

	const id = useRecoilValue(aliveIdState);

	const [aliveInfo, setAliveInfo] = useState<AliveInfoDataType>(initialAliveInfo);
	const [, setSavedAliveInfo] = useState<AliveInfoDataType>(initialAliveInfo);

	// 상세 정보
	const updateMutation = useMutation({
		mutationFn: () => postMutation([...statisticsQueryKey.aliveDetail(), null, { aliveNo: id }]),
		onSuccess: (data) => {
			const aliveInfo = {
				...initialAliveInfo,
				aliveDevicetype: data.aliveDevicetype,
				aliveType: data.aliveType,
				aliveMac: data.aliveMac,
				aliveName: data.aliveName,
				aliveLine: data.aliveLine,
				aliveStation: data.aliveStation,
				aliveSimpaddr: data.aliveSimpaddr,
				aliveSerial: data.aliveSerial,
				alivePhone: data.alivePhone,
				aliveDate: data.aliveDate,
				aliveDate2: data.aliveDate2,
				aliveTime: data.aliveTime,
				aliveMemo: data.aliveMemo,
			};

			setAliveInfo(aliveInfo);
			setSavedAliveInfo(aliveInfo);
		},
	});

	// 등록 / 수정
	const insertQueryKey = 'aliveUpdate';
	const insertQueryParams = id ? { ...aliveInfo, aliveNo: id } : aliveInfo;
	const insertMutation = useInvalidateFromMutation(statisticsQueryKey, insertQueryKey, insertQueryParams);

	const handleChangeAliveInfo = (name: string, value: string) => {
		setAliveInfo((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();

		insertMutation.mutate();
	};

	const { closeContentModal } = useContentsModal();

	const handleModalClose = () => {
		setAliveInfo(initialAliveInfo);
		closeContentModal();
	};

	useEffect(() => {
		if (id) updateMutation.mutate();
		else setAliveInfo(initialAliveInfo);
	}, [id]);

	useEffect(() => {
		if (insertMutation.isSuccess) {
			handleModalClose();
		}
	}, [insertMutation.isSuccess]);

	const [aliveDate2, setAliveDate2] = useState(new Date());

	const handleDateChange = (date: Date | null) => {
		if (date) {
			setAliveDate2(date);

			const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
			setAliveInfo((prev) => ({
				...prev,
				aliveDate2: formattedDate,
			}));
		}
	};

	return (
		<>
			<div className="modal-header">
				<h4 className="modal-title">고장 정보 수정</h4>
			</div>
			<form
				className="form-info form-info-vertical px-2"
				onSubmit={(event) => handleSubmit(event)}
				style={{ maxWidth: '900px', width: '90vw' }}
			>
				<div className="modal-body">
					<CustomRow>
						<div className="form-grid">
							<CustomText labelTitle="호선" text={aliveInfo.aliveLine} />
						</div>
						<div className="form-grid">
							<CustomText labelTitle="역사명" text={aliveInfo.aliveStation} />
						</div>
					</CustomRow>
					<CustomRow>
						<div className="form-grid">
							<CustomText labelTitle="세부설치위치" text={aliveInfo.aliveSimpaddr} />
						</div>
					</CustomRow>
					<CustomRow>
						<div className="form-grid">
							<CustomText labelTitle="라우터" text={aliveInfo.aliveSerial} />
						</div>
						<div className="form-grid">
							<CustomText labelTitle="전화번호" text={aliveInfo.alivePhone} />
						</div>
					</CustomRow>
					<CustomRow>
						{memberFlag === 1 && (
							<div className="form-grid">
								<CustomText labelTitle="MAC" text={aliveInfo.aliveMac} />
							</div>
						)}
						<div className="form-grid">
							<CustomText labelTitle="고장일시" text={aliveInfo.aliveDate} />
						</div>
					</CustomRow>
					{/*
					<CustomRow>
						<div className="form-grid">
							<DatePicker
								dateFormat="yyyy-MM-dd HH:mm:ss"
								timeFormat="HH:mm:ss"
								timeIntervals={1}
								timeCaption="시분초"
								showTimeSelect
								selected={aliveDate2}
								onChange={handleDateChange}
								customInput={
									<CustomInput
										labelTitle="수리일시"
										name="aliveDate2"
										defaultValue={aliveInfo.aliveDate2}
										handleState={handleChangeAliveInfo}
									/>
								}
							/>
							<CustomInput
								labelTitle="수리일시"
								name="aliveDate2"
								defaultValue={aliveInfo.aliveDate2}
								handleState={handleChangeAliveInfo}
							/>
						</div>
						<div className="form-grid">
							<CustomText labelTitle="처리소요시간" text={aliveInfo.aliveTime} />
						</div>
					</CustomRow>
					*/}
					<CustomRow>
						<div className="form-grid">
							<CustomInput
								labelTitle="메모"
								name="aliveMemo"
								defaultValue={aliveInfo.aliveMemo}
								handleState={handleChangeAliveInfo}
							/>
						</div>
					</CustomRow>
				</div>
				<div className="modal-footer">
					<button type="submit" className="btn btn-navy">
						수정
					</button>
					<button type="button" className="btn btn-default" onClick={handleModalClose}>
						닫기
					</button>
				</div>
			</form>
		</>
	);
}
