import { useEffect, useState } from 'react';

import { SecureStorage } from '@/plugin/crypto';

export default function Footer() {
	return (
		<footer id="footer" className="app-footer">
			<LightModeToggle />
			<div className="copyright text-end">
				<small>Copyright SAFETY-LAB CO,LTD Allright Reserved.</small>
			</div>
		</footer>
	);
}

function LightModeToggle() {
	const secureStorage = new SecureStorage(sessionStorage);
	const userInfo = secureStorage.getItem('user-storage', 'user-storage');

	let member_lightMode;
	if (userInfo) {
		member_lightMode = userInfo.member_lightMode;
	} else {
		member_lightMode = false;
	}

	const [lightMode, setLightMode] = useState(member_lightMode);

	useEffect(() => {
		if (lightMode) {
			document.body.classList.remove('dark-mode');
			if (userInfo) {
				secureStorage.setItem(
					'user-storage',
					{
						...userInfo,
						member_lightMode: true,
					},
					'user-storage',
				);
			}
		} else {
			document.body.classList.add('dark-mode');
			if (userInfo) {
				secureStorage.setItem(
					'user-storage',
					{
						...userInfo,
						member_lightMode: false,
					},
					'user-storage',
				);
			}
		}
	}, [lightMode]);

	return (
		<div className="form-check form-switch">
			<label className="form-check-label" htmlFor="check-lightMode">
				라이트모드
			</label>
			<input
				className="form-check-input"
				type="checkbox"
				id="check-lightMode"
				checked={lightMode}
				onChange={(event) => setLightMode(event.target.checked)}
			/>
		</div>
	);
}
